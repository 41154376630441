import styled from "styled-components";
import bgMobile from "../../assets/img/bg-validate-mbl.jpg";
import bg from "../../assets/img/bg-validate.jpg";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5.125rem 2rem;
  background: url(${bgMobile});

  @media (min-width: 768px) {
    padding: 6.875rem 3.5rem 4rem;
    text-align: left;
    background: url(${bg}) no-repeat;
    background-size: cover;
  }
`;

export const Header = styled.header`
  @media (min-width: 768px) {
    img {
      width: 152px;
      height: 153px;
    }
  }
`;

export const Content = styled.div`
  margin-top: 2.5rem;

  @media (min-width: 768px) {
    margin-top: 6.5rem;
  }

  @media (min-width: 1024px) {
    width: 920px;
  }

  label {
    font-size: 1.125rem;
    display: block;
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      font-size: 1.75rem;
      margin-bottom: 2rem;
    }
  }

  input {
    display: block;
    width: 100%;
    padding: 1rem 1.2rem;
    font-size: 1rem;
    color: #000;
    margin-bottom: 2rem;
    border-radius: var(--border-radius);

    @media (min-width: 768px) {
      margin-bottom: 3.2rem;
      height: 4.2rem;
      font-size: 1.4rem;
    }
  }

  button {
    font-size: 1rem;
    font-weight: 800;
    width: 100%;
    background-color: #ea1fee;
    padding: 0.8rem 1.2rem;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 0.3s ease-in-out;

    svg {
      margin-left: 0.2rem;
    }

    @media (min-width: 768px) {
      font-size: 1.375rem;
      height: 4rem;
      width: 22.5rem;
      margin: 5.25rem auto 0;

      svg {
        font-size: 1.5rem;
      }
    }
  }

  button:hover,
  button:focus {
    filter: grayscale(0.4);
  }
`;
