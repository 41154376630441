import { useState } from "react";
import { Container, Header, Content } from "./styles";
import logoMobile from "../../assets/img/logo-mobile.svg";
import logo from "../../assets/img/logo.svg";

interface ValidationProps {
  formId: number;
}

export default function Validation({ formId }: ValidationProps) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const url = "https://kenzie39049.activehosted.com/proc.php";

  return (
    <Container>
      <Header>
        <picture>
          <source media="(min-width: 768px)" srcSet={logo} />
          <img
            src={logoMobile}
            alt="Logo Kenzie Academy"
            width="160"
            height="40"
          />
        </picture>
      </Header>
      <Content>
        <form method="POST" action={url}>
          <input type="hidden" name="u" value={formId} />
          <input type="hidden" name="f" value={formId} />
          <input type="hidden" name="s" />
          <input type="hidden" name="c" value="0" />
          <input type="hidden" name="m" value="0" />
          <input type="hidden" name="act" value="sub" />
          <input type="hidden" name="v" value="2" />

          <label htmlFor="fullname">
            Insira o <strong>nome</strong> que você{" "}
            <strong>quer que apareça</strong> no certificado.
          </label>
          <input
            id="fullname"
            name="fullname"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Seu nome"
            maxLength={30}
            required
          />
          <label htmlFor="email">
            Insira o <strong>email</strong> para validar o seu certificado.
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="email@exemplo.com"
            required
          />

          <input type="hidden" name="field[1]" value="" />
          <input type="hidden" name="field[4]" value="" />
          <input type="hidden" name="field[3]" value="" />
          <input type="hidden" name="field[90]" value="" />
          <input type="hidden" name="field[8]" value="" />
          <input type="hidden" name="field[9]" value="" />
          <input type="hidden" name="field[10]" value="" />
          <input type="hidden" name="field[11]" value="" />

          <button type="submit">Validar certificado</button>
        </form>
      </Content>
    </Container>
  );
}
