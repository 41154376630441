import { Route, Routes, Navigate } from "react-router-dom";
import Validation from "../pages/Validation";
import Download from "../pages/Download";
import Modal from "../pages/Modal";
import signatures from "../assets/img/signatures.png";
import { EventName } from "../model/enums/event";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Validation formId={183} />} />
      <Route path="/burgerkingbrasil" element={<Validation formId={117} />} />
      <Route path="/braziliansintech" element={<Validation formId={123} />} />
      <Route path="/potenciatech" element={<Validation formId={127} />} />
      <Route path="/educatransforma" element={<Validation formId={131} />} />
      <Route path="/bemol" element={<Validation formId={145} />} />
      <Route
        path="/certificado-maratona"
        element={<Download eventName={EventName.MARATONA} />}
      />
      <Route
        path="/certificado-burgerkingbrasil"
        element={<Download eventName={EventName.BK} />}
      />
      <Route
        path="/certificado-braziliansintech"
        element={<Download eventName={EventName.BIT} />}
      />
      <Route
        path="/certificado-potenciatech"
        element={<Download eventName={EventName.MOVTECH} />}
      />
      <Route
        path="/certificado-educatransforma"
        element={<Download eventName={EventName.EDUCA} />}
      />
      <Route
        path="/certificado-bemol"
        element={<Download eventName={EventName.BEMOL} />}
      />
      <Route path="/certificado-solicitado" element={<Modal />} />

      <Route
        path="/signatures"
        element={
          <div>
            <img src={signatures} alt="assinaturas" />
          </div>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
