import styled from "styled-components";
import bgMobile from "../../assets/img/bg-validate-mbl.jpg";
import bg from "../../assets/img/bg-validate.jpg";

export const Container = styled.div`
  min-height: 100vh;
  text-align: center;
  padding-top: 5.125rem;
  background: url(${bgMobile});

  @media (min-width: 768px) {
    padding-top: 6.875rem;
    background: url(${bg}) no-repeat;
    background-size: cover;
  }
`;

export const Header = styled.header`
  @media (min-width: 768px) {
    img {
      width: 152px;
      height: 153px;
    }
  }
`;

export const PopUp = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  background: rgba(196, 196, 196, 0.5);
  backdrop-filter: blur(5px);
`;

export const Content = styled.p`
  color: #2d2d2d;
  background-color: #fff;
  padding: 3rem 2rem;
  width: 90%;
  max-width: 19rem;
  border-radius: var(--border-radius);
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);

  @media (min-width: 1024px) {
    font-size: 1.75rem;
    padding: 5rem 4rem;
    max-width: 32rem;
  }
`;
