export enum EventName {
  BK = "BK",
  BIT = "BIT",
  MOVTECH = "MOVTECH",
  EDUCA = "EDUCA",
  BEMOL = "BEMOL",
  MARATONA = "MARATONA",
  MODULO1 = "MODULO1",
  MODULO2 = "MODULO2",
  MODULO4 = "MODULO4",
  MODULO5 = "MODULO5",
  FRONTENDPT = "FRONTENDPT",
  FRONTENDEN = "FRONTENDEN",
  FULLSTACKPT = "FULLSTACKPT",
  FULLSTACKEN = "FULLSTACKEN",
}
