import styled from "styled-components";
import bgMobile from "../../assets/img/bg-mobile.jpg";
import bg from "../../assets/img/bg.jpg";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  text-align: center;
  padding: 4rem 2rem 1rem;
  background: url(${bgMobile});

  @media (min-width: 428px) {
    padding: 5.125rem 3.5rem;
  }

  @media (min-width: 768px) {
    padding: 6rem 3.5rem 4rem;
    background: url(${bg}) no-repeat;
    background-size: cover;
  }
`;

export const Header = styled.header`
  @media (min-width: 768px) {
    img {
      width: 152px;
      height: 153px;
    }
  }
`;

export const Content = styled.p`
  max-width: 27.5rem;
  font-size: 1rem;
  padding: 2rem 1rem;
  background-color: rgba(234, 31, 238, 0.24);
  border-radius: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  @media (min-width: 1024px) {
    font-size: 1.5rem;
    max-width: 40rem;
  }

  button {
    font-size: 1rem;
    text-transform: lowercase;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    background-color: transparent;
    transition: color 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: #ea1fee;
    }

    @media (min-width: 1024px) {
      font-size: 1.375rem;
    }
  }
`;

export const Share = styled.div`
  @media (min-width: 1024px) {
    font-size: 1.375rem;
  }

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    transition: color 0.3s ease-in-out;

    img {
      object-fit: contain;
    }

    span {
      font-size: 1.125rem;
      padding-top: 0.5rem;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: #ea1fee;
    }

    @media (min-width: 1024px) {
      margin-top: 2.75rem;

      img {
        width: 60px;
        height: 60px;
      }

      span {
        padding-top: 0.8rem;
      }
    }
  }
`;
