import { Container, Header, PopUp, Content } from "./styles";
import logoMobile from "../../assets/img/logo-mobile.svg";
import logo from "../../assets/img/logo.svg";

export default function Modal() {
  return (
    <>
      <Container>
        <Header>
          <picture>
            <source media="(min-width: 768px)" srcSet={logo} />
            <img
              src={logoMobile}
              alt="Logo Kenzie Academy"
              width="160"
              height="40"
            />
          </picture>
        </Header>
      </Container>
      <PopUp>
        <Content>
          Tudo certo! <br />
          Agora é só aguardar mais instruções via e-mail para gerar o
          certificado.
        </Content>
      </PopUp>
    </>
  );
}
