import templateBK from "../assets/templates/certificate-bk.jpg";
import templateBIT from "../assets/templates/certificate-bit.png";
import templateMovtech from "../assets/templates/certificate-movtech.png";
import templateEduca from "../assets/templates/certificate-educa.jpg";
import templateBemol from "../assets/templates/certificate-bemol.png";
import templateMaratona from "../assets/templates/certificate.png";
import templateM1 from "../assets/templates/certificate-m1.png";
import templateM2 from "../assets/templates/certificate-m2.png";
import templateM4 from "../assets/templates/certificate-m4.png";
import templateM5 from "../assets/templates/certificate-m5.png";
import templateFrontPt from "../assets/templates/certificate-front-pt.jpg";
import templateFrontEn from "../assets/templates/certificate-front-en.png";
import templateFullStackPt from "../assets/templates/certificate-fullstack-pt.jpg";
import templateFullStackEn from "../assets/templates/certificate-fullstack-en.jpg";

export const template = {
  BK: {
    color: "#FF8732",
    template: templateBK,
    pdfName: "certificado-bkb-code.pdf",
    size: 120,
  },
  BIT: {
    color: "#C000FF",
    template: templateBIT,
    pdfName: "certificado-mulheres-em-tech.pdf",
    size: 120,
  },
  MOVTECH: {
    color: "#f5f5f5",
    template: templateMovtech,
    pdfName: "certificado-maratona-premiada.pdf",
    size: 120,
  },
  EDUCA: {
    color: "#0000ff",
    template: templateEduca,
    pdfName: "certificado-codar-para-transformar.pdf",
    size: 116,
  },
  BEMOL: {
    color: "#2D2B4B",
    template: templateBemol,
    pdfName: "certificado-be-dev.pdf",
    size: 120,
  },
  MARATONA: {
    color: "#f7c82a",
    template: templateMaratona,
    pdfName: "certificado-maratona-de-programação.pdf",
    size: 146,
  },
  MODULO1: {
    color: "#2321FF",
    template: templateM1,
    pdfName: "certificado-módulo-1-de-programação.pdf",
    size: 95,
  },
  MODULO2: {
    color: "#2321FF",
    template: templateM2,
    pdfName: "certificado-módulo-2-de-programação.pdf",
    size: 95,
  },
  MODULO4: {
    color: "#2321FF",
    template: templateM4,
    pdfName: "certificado-módulo-4-de-programação.pdf",
    size: 95,
  },
  MODULO5: {
    color: "#2321FF",
    template: templateM5,
    pdfName: "certificado-módulo-5-de-programação.pdf",
    size: 95,
  },
  FRONTENDPT: {
    color: "#fff",
    template: templateFrontPt,
    pdfName: "PT-certificado-frontend.pdf",
    size: 115,
  },
  FRONTENDEN: {
    color: "#fff",
    template: templateFrontEn,
    pdfName: "EN-certificate-frontend.pdf",
    size: 115,
  },
  FULLSTACKPT: {
    color: "#fff",
    template: templateFullStackPt,
    pdfName: "PT-certificado-fullstack.pdf",
    size: 115,
  },
  FULLSTACKEN: {
    color: "#fff",
    template: templateFullStackEn,
    pdfName: "EN-certificate-fullstack.pdf",
    size: 115,
  },
};
