import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import querySearch from "stringquery";
import decodeUriComponent from "decode-uri-component";
import { jsPDF } from "jspdf";
import { Container, Header, Content, Share } from "./styles";
import logoMobile from "../../assets/img/logo-mobile.svg";
import logo from "../../assets/img/logo.svg";
import icon from "../../assets/img/icon-linkedin.svg";
import { template } from "../../model/template";
import { EventName } from "../../model/enums/event";

interface DownloadProps {
  eventName: EventName;
}

export default function Download({ eventName }: DownloadProps) {
  const location = useLocation();
  const params = querySearch(location.search);

  // eslint-disable-next-line
  const [name, setName] = useState(() => {
    if (params.fullname) {
      return decodeUriComponent(params.fullname);
    }
    return params.fullname;
  });

  const pdfInit = () => {
    let pdfDocument = new jsPDF({
      orientation: "landscape",
    });

    let certificateImg = new Image();

    certificateImg.onload = function () {
      pdfDocument.addImage(template[eventName].template, 0, 0, 297, 210);
      pdfDocument.setFontSize(40);
      pdfDocument.setTextColor(template[eventName].color);
      pdfDocument.setFont("Helvetica", "bold");
      pdfDocument.text(
        name,
        pdfDocument.internal.pageSize.width / 2,
        template[eventName].size,
        undefined,
        "center"
      );

      pdfDocument.save(template[eventName].pdfName);
    };

    certificateImg.crossOrigin = "";
    certificateImg.src = template[eventName].template;
  };

  useEffect(() => {
    pdfInit();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header>
        <picture>
          <source media="(min-width: 768px)" srcSet={logo} />
          <img
            src={logoMobile}
            alt="Logo Kenzie Academy"
            width="160"
            height="40"
          />
        </picture>
      </Header>
      <Content>
        Caso o download não inicie automaticamente,{" "}
        <button onClick={pdfInit}>clique aqui</button> para baixar.
      </Content>

      <Share>
        <p>
          Faça um post para compartilhar seu primeiro passo em direção a sua
          entrada na programação.
        </p>
        <a href="https://linkedin.com" target="_blank" rel="noreferrer">
          {" "}
          <img src={icon} alt="Logo LinkedIn" width="38" height="38" />{" "}
          <span>LinkedIn</span>{" "}
        </a>
      </Share>
    </Container>
  );
}
