import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html, body, div, p, header, footer, main, section, nav, ul, li, a, button, input, img, article, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    border: none;
}

:root {
    --text-color: #fff;
    --border-radius: 10px;
}

html {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
}

body, a, input {
    font-family: "Montserrat", sans-serif;
    color: var(--text-color);
}

button, h1 {
    font-family: "Jet Brains", monospace;
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 800;
}

button {
    cursor: pointer;
}
`;
